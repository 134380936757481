import {db}  from '../firebase-config'
import{collection,getDocs,getDoc,addDoc,updateDoc,deleteDoc,doc} from 'firebase/firestore'
import {orderBy,startAt,query,} from 'firebase/firestore'

class PropertyDataServices{
    addBooks=(newBook)=>{
        return addDoc(bookRef2,newBook);
      }
  
      updateBrequest=(id,updatedBook)=>{
        const bookDoc= doc(db,"property_layout",id);
        return updateDoc(bookDoc,updatedBook);
      }
     
      deleteBook=(id)=>{
          const bookDoc= doc(db,"property_layout",id);
          deleteDoc(bookDoc);
      }
  
      getAllBooks=()=>{
          return getDocs(q2)
      }
  
      getBook=(id)=>{
        const bookDoc= doc(db,"property_layout",id);
        return getDoc(bookDoc);
      }
}


const bookRef2=collection(db,"property_layout");

const q2=query(bookRef2,orderBy("visibleInApp"),startAt("0"));


export default new  PropertyDataServices();
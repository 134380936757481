import {db}  from '../firebase-config'
import{collection,getDocs,getDoc,addDoc,updateDoc,deleteDoc,doc} from 'firebase/firestore'
import {orderBy,startAt,query,} from 'firebase/firestore'



class ResaleDataServices{
    addBooks=(newBook)=>{
        return addDoc(bookRef3,newBook);
      }
  
      updateBrequest=(id,updatedBook)=>{
        const bookDoc= doc(db,"resaleandrental",id);
        return updateDoc(bookDoc,updatedBook);
      }
     
      deleteBook=(id)=>{
          const bookDoc= doc(db,"resaleandrental",id);
          deleteDoc(bookDoc);
      }
  
      getAllBooks=()=>{
          return getDocs(q3)
      }
  
      getBook=(id)=>{
        const bookDoc= doc(db,"resaleandrental",id);
        return getDoc(bookDoc);
      }


}


const bookRef3=collection(db,"resaleandrental");
const q3=query(bookRef3,orderBy("visibleInApp"),startAt("0"));
export default new ResaleDataServices();







import {db}  from '../firebase-config'
import{collection,getDocs,getDoc,addDoc,updateDoc,deleteDoc,doc} from 'firebase/firestore'




class AddDataServices{
    addBooks=(newBook)=>{
      return addDoc(bookRef,newBook);
    }

    updateBrequest=(id,updatedBook)=>{
      const bookDoc= doc(db,"post Builder Project",id);
      return updateDoc(bookDoc,updatedBook);
    }
   
    deleteBook=(id)=>{
        const bookDoc= doc(db,"topAdd",id);
        deleteDoc(bookDoc);
    }

    deleteBook2down=(id)=>{
      const bookDoc= doc(db,"downAdd",id);
      deleteDoc(bookDoc);
  }


    getAllBooks=()=>{
        return getDocs(bookRef)
    }

    getBook=(id)=>{
      const bookDoc= doc(db,"post Builder Project",id);
      return getDoc(bookDoc);
    }
   
    addDownbook=(newBook)=>{
        return addDoc(bookRef2,newBook);
    }

    getDownAllbooks=()=>{
        return getDocs(bookRef2);
    }

}

const bookRef=collection(db,"topAdd");
const bookRef2=collection(db,"downAdd");
export default new AddDataServices();
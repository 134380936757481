import React from 'react'
import Navbar from './Navbar'
import { useEffect } from 'react'
import BvDataServices from '../services/bv.services'
import { useState } from 'react'


export default function Bv() {
    const [books, setbooks] = useState([])
  useEffect(()=>{
    getBooks();
  },)

 const APHandeler= async (id)=>{
    const bookSnap= await BvDataServices.getBook(id);
    const newBook={
        imageURL:bookSnap.data().imageURL,
        name:bookSnap.data().name,
        emailAddress:bookSnap.data().emailAddress,
        addharCardNumber:bookSnap.data().addharCardNumber,
        phoneNumber:bookSnap.data().phoneNumber,
        userID:bookSnap.data().userID,
        verified:"-1"        
       }
       BvDataServices.updateBrequest(id,newBook);
       alert("your request has been approved");
     
 }

 const DEHandeler= async (id)=>{
    const bookSnap= await BvDataServices.getBook(id);
    const newBook={
        imageURL:bookSnap.data().imageURL,
        name:bookSnap.data().name,
        emailAddress:bookSnap.data().emailAddress,
        addharCardNumber:bookSnap.data().addharCardNumber,
        phoneNumber:bookSnap.data().phoneNumber,
        userID:bookSnap.data().userID,
        verified:"-2"        
       }
       BvDataServices.updateBrequest(id,newBook);
         alert("your request has been rejected");
 }






  const getBooks= async ()=>{
    const data= await BvDataServices.getAllBooks();
    console.log(data);
    setbooks(data.docs.map((doc) =>({...doc.data(), id:doc.id})))
  }

  return (
    <>
   
    <Navbar />
    <div className="container">
        <div className="row mt-3">
        <h2 className='text-center'>Broker_Verificasation</h2>
        {books.map((doc,index)=>{
        return(
            <div className="col-md-4" key={doc.id}>
            <div className="card" style={{width: "18rem"}}>
  <img className="card-img-top" src={doc.imageURL} alt=" cap" />
  <div className="card-body">
    <h5 className="card-title">name:{doc.name}</h5>
    <p className="card-text">email: {doc.emailAddress}</p>
    <p className="card-text">addharCardNumber: {doc.addharCardNumber}</p>
    <p className="card-text">phoneNumber: {doc.phoneNumber}</p>
    <p className="card-text">userID: {doc.userID}</p>
    <p className='card-text'>verified: {doc.verified}</p>
    <button className="btn btn-success mx-2" onClick={(e)=>APHandeler(doc.id)} >Approve</button>
    <button className="btn btn-danger" onClick={(e)=>DEHandeler(doc.id)}  >Reject</button>
  
  </div>
</div>
            </div>
        )
    })}
        </div>
    </div>
    
    </>
  )
}

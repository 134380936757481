import {db}  from '../firebase-config'
import{collection,getDocs,} from 'firebase/firestore'



class LoanDataServices{
    getAllBooks=()=>{
        return getDocs(loanRef);
    }
}


const loanRef=collection(db,"requestloan")
export default new LoanDataServices();














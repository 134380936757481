import React, { useState } from 'react'
import Navbar from './Navbar'
import {storage} from '../firebase-config'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import AddDataServices from '../services/Addvertise'
import { useEffect } from 'react';




export default function Appadver() {
  
  const [books, setbooks] = useState([])
  useEffect(()=>{
    getBooks();
  },[])


  const getBooks= async ()=>{
    const data= await AddDataServices.getAllBooks();
    console.log(data);
    setbooks(data.docs.map((doc) =>({...doc.data(), id:doc.id})))
  }

 const [progress,setprogress]= useState(0);

  const formHandler=(e)=>{
   e.preventDefault();
   const file=e.target[0].files[0];
   uploadFiles(file);
  }
 
  const uploadFiles=(file)=>{
     
    if(!file) return;
    const storageref=ref(storage,`/files/${file.name}`);
    const uploadTask=uploadBytesResumable(storageref,file);

    uploadTask.on("state_changed", (snapshot)=>{
      const prog=Math.round((snapshot.bytesTransferred / snapshot.totalBytes)*100);
      setprogress(prog)
    },(err)=>console.log(err),
      ()=>{
        getDownloadURL(uploadTask.snapshot.ref)
        .then(url=>{
          const newbook={
            url:url
          }
          AddDataServices.addBooks(newbook);
        })
      }
    );

  
  }
// above is for up-Addlogic



const [books2, setbooks2] = useState([])
  useEffect(()=>{
    getBooks2();
  },[])

  const getBooks2= async ()=>{
    const data= await AddDataServices.getDownAllbooks();
    console.log(data);
    setbooks2(data.docs.map((doc) =>({...doc.data(), id:doc.id})))
  }





const [progress2,setprogress2]= useState(0);



const formHandler2=(e)=>{
  e.preventDefault();
  const file=e.target[0].files[0];
  uploadFiles2(file);
 
 }


 const uploadFiles2=(file)=>{
     
  if(!file) return;
  const storageref=ref(storage,`/files-2/${file.name}`);
  const uploadTask=uploadBytesResumable(storageref,file);

  uploadTask.on("state_changed", (snapshot)=>{
    const prog=Math.round((snapshot.bytesTransferred / snapshot.totalBytes)*100);
    setprogress2(prog)
  },(err)=>console.log(err),
    ()=>{
      getDownloadURL(uploadTask.snapshot.ref)
      .then(url=>{
        const newbook={
          url:url
        }
        AddDataServices.addDownbook(newbook);
      })
    }
  );


}
//for deleteing the photo
const DE =async(id)=>{
  await AddDataServices.deleteBook(id);
  getBooks();
}
const DE2 =async(id)=>{
  await AddDataServices.deleteBook2down(id);
  getBooks2();
}
  

  return (
<>


<Navbar />
<div className="container">
  <div className="row up-add-row ">
    <div className="col-6 d-flex flex-column">
        <h1>hero section</h1>
        {books.map((doc,index)=>{
  return(
    <div  className="img" key={doc.id}>
       <img width={500} id='hero-img' alt='okk' src={doc.url} />
       <button onClick={(e)=>DE(doc.id)} className='btn btn-danger' >DELETE</button>
     
    </div>
  )
})}
             
        </div>
    <div className="col-6 mt-3">
      <form onSubmit={formHandler} >
       <input type='file' />
       <button type='submit'>Upload</button>
      </form>
      <h3>{progress}%</h3>
    </div>
  </div>
















{/* belwo is for footer html */}


   <div className="row mt-4">
    <div className="col-6 ">
      <h2>Footersection</h2>
      {books2.map((doc,index)=>{
  return(
    <div className="img-add-down" key={doc.id}>
      <img width={500} id='footer-img' alt='okk' src={doc.url} />
      <button onClick={(e)=>DE2(doc.id)} className='btn btn-danger' >DELETE</button>
    </div>
  )
})}
      
    </div>
    <div className="col-6">
      
    <form onSubmit={formHandler2} >
       <input type='file' />
       <button type='submit'>Upload</button>
      </form>
      <h3>{progress2}%</h3>
    </div>
  </div>
</div>

   
</>
  ) 
  }

import {db}  from '../firebase-config'
import{collection,getDocs,getDoc,addDoc,updateDoc,deleteDoc,doc} from 'firebase/firestore'
import {orderBy,startAt,query,} from 'firebase/firestore'

// this is actual post builderProject
class BrokerDataServices{
    addBooks=(newBook)=>{
      return addDoc(bookRef,newBook);
    }

    updateBrequest=(id,updatedBook)=>{
      const bookDoc= doc(db,"postBuilderProject",id);
      return updateDoc(bookDoc,updatedBook);
    }
   
    deleteBook=(id)=>{
        const bookDoc= doc(db,"postBuilderProject",id);
        deleteDoc(bookDoc);
    }

    getAllBooks=()=>{
        return getDocs(q)
    }

    getBook=(id)=>{
      const bookDoc= doc(db,"postBuilderProject",id);
      return getDoc(bookDoc);
    }


}

const bookRef=collection(db,"postBuilderProject");
const q=query(bookRef,orderBy("visibleInApp"),startAt("0"));



export default new BrokerDataServices();

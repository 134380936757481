import React from 'react'
import Navbar from './Navbar'
import PropertyDataServices from '../services/property.services'
import { useEffect } from 'react'
import { useState } from 'react'



export default function Propertyveri() {
   
  const [books, setbooks] = useState([])
  useEffect(()=>{
    getBooks();
  },)
   
 const APHandeler= async (id)=>{
  const bookSnap= await PropertyDataServices.getBook(id);
  const newBook={
    images:bookSnap.data().images,
    title:bookSnap.data().title,
    communteTime:bookSnap.data().communteTime,
    convenience:bookSnap.data().convenience,
    enviroment:bookSnap.data().enviroment,
    katha:bookSnap.data().katha,
    kathaDescription:bookSnap.data().kathaDescription,
    leisure:bookSnap.data().leisure,
    livingAreafloor:bookSnap.data().livingAreafloor,
    location:bookSnap.data().location,
    masterbedroomfloor:bookSnap.data().masterbedroomfloor,
    objectID:bookSnap.data().objectID,
    otherbedroomfloor:bookSnap.data().otherbedroomfloor,
    phoneNo:bookSnap.data().phoneNo,
    price:bookSnap.data().price,
    safety:bookSnap.data().safety,
    specification:bookSnap.data().specification,
    sports:bookSnap.data().sports,
    structure:bookSnap.data().structure,
    subRun:bookSnap.data().subRun,
    userID:bookSnap.data().userID,
    walls:bookSnap.data().walls,
    visibleInApp:"-1"
    
  }

  PropertyDataServices.updateBrequest(id,newBook);
  alert("your request has been approved");
 }

 const DEHandeler= async (id)=>{
  const bookSnap= await PropertyDataServices.getBook(id);
  const newBook={
    images:bookSnap.data().images,
    title:bookSnap.data().title,
    communteTime:bookSnap.data().communteTime,
    convenience:bookSnap.data().convenience,
    enviroment:bookSnap.data().enviroment,
    katha:bookSnap.data().katha,
    kathaDescription:bookSnap.data().kathaDescription,
    leisure:bookSnap.data().leisure,
    livingAreafloor:bookSnap.data().livingAreafloor,
    location:bookSnap.data().location,
    masterbedroomfloor:bookSnap.data().masterbedroomfloor,
    objectID:bookSnap.data().objectID,
    otherbedroomfloor:bookSnap.data().otherbedroomfloor,
    phoneNo:bookSnap.data().phoneNo,
    price:bookSnap.data().price,
    safety:bookSnap.data().safety,
    specification:bookSnap.data().specification,
    sports:bookSnap.data().sports,
    structure:bookSnap.data().structure,
    subRun:bookSnap.data().subRun,
    userID:bookSnap.data().userID,
    walls:bookSnap.data().walls,
    visibleInApp:"-2"
    
  }

  PropertyDataServices.updateBrequest(id,newBook);
  alert("your request has been rejected");
 }

  const getBooks= async ()=>{
    const data= await PropertyDataServices.getAllBooks();
    console.log(data);
    setbooks(data.docs.map((doc) =>({...doc.data(), id:doc.id})))
  }

  return (
    <>
    <Navbar />
     <div className="container">
        <div className="row">
          <h2 className='text-center' >propertylayouts</h2>
       
        {books.map((doc,index)=>{
      return(
        <div className="col-md-4">
        <div className="card" style={{width: "18rem"}}>
<img className="card-img-top peroperty-img " src={doc.images} alt=" cap" />
<div className="card-body">
<h5 className="card-title">title:{doc.title}</h5>
<p className='card-title'>communteTime:{doc.communteTime}</p>
<p className='card-text'>convenience:{doc.convenience}</p>
<p className='card-text'>enviroment:{doc.enviroment}</p>
<p className='card-text'>katha:{doc.katha}</p>
<p className='card-text'>kathaDescription:{doc.kathaDescription}</p>
<p className='card-text'>leisure:{doc.leisure}</p>
<p className='card-text'>livingAreafloor:{doc.livingAreafloor}</p>
<p className='card-text'>location:{doc.location}</p>
<p className='card-text'>masterbedroomfloor:{doc.masterbedroomfloor}</p>

<p className='card-text'>otherbedroomfloor:{doc.otherbedroomfloor}</p>
<p className='card-text'>phoneNo:{doc.phoneNo}</p>
<p className='card-text'>price:{doc.price}</p>
<p className='card-text'>safety:{doc.safety}</p>
<p className='card-text'>specification:{doc.specification}</p>
<p className='card-text'>sports:{doc.sports}</p>
<p className='card-text'>structure:{doc.structure}</p>

<p className='card-text'>userID:{doc.userID}</p>
<p className='card-text'>walls:{doc.walls}</p>

<button className='btn btn-primary mx-3'  onClick={(e)=>APHandeler(doc.id)}  >Approve</button>
<button className='btn btn-danger '  onClick={(e)=>DEHandeler(doc.id)}  >Reject</button>
</div>
</div>
        </div>
      )
    })}
        </div>
     </div>
    </>
  )
}

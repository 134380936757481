import React from 'react'
import { NavLink } from 'react-router-dom'
import web from '../images/web-3.jpeg'
export default function Navbar() {
  return (
   <>
   <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid">
    {/* <a className="navbar-brand" href="/">UK9 Squares</a> */}
   <img alt='logo-img' src={web} style={{height:"40px"}}  ></img>
   
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse  " id="navbarNav">
      <ul className="navbar-nav ">
          <li className="nav-item">
          {/* <a className="nav-link active" aria-current="page" href="/">Home</a> */}
           <NavLink className='nav-link' to='/login-page/bv' >Broker-Verification</NavLink>
        </li> 

        <li className="nav-item">
           <NavLink className='nav-link' to='/login-page/pv' >Property-Layouts</NavLink>
        </li> 

        
        <li className="nav-item">
           <NavLink className='nav-link' to='/login-page/app-ad' >App-Addvertisement</NavLink>
        </li> 

        
        <li className="nav-item">
           <NavLink className='nav-link' to='/login-page/loan' >Loan-Request</NavLink>
        </li> 

        <li className="nav-item">
           <NavLink className='nav-link' to='/login-page/rea' >Resaleandrental</NavLink>
        </li> 
  
  
  
      </ul>
    </div>
  </div>
</nav>
   </>
  )
}

import {db}  from '../firebase-config'
import{collection,getDocs,getDoc,addDoc,updateDoc,deleteDoc,doc} from 'firebase/firestore'
import {orderBy,startAt,query,} from 'firebase/firestore'

class BvDataServices{
    addBooks=(newBook)=>{
      return addDoc(bookRef,newBook);
    }

    updateBrequest=(id,updatedBook)=>{
      const bookDoc= doc(db,"broker_verification",id);
      return updateDoc(bookDoc,updatedBook);
    }
   
    deleteBook=(id)=>{
        const bookDoc= doc(db,"broker_verification",id);
        deleteDoc(bookDoc);
    }

    getAllBooks=()=>{
        return getDocs(q)
    }

    getBook=(id)=>{
      const bookDoc= doc(db,"broker_verification",id);
      return getDoc(bookDoc);
    }


}
const bookRef=collection(db,"broker_verification");
const q=query(bookRef,orderBy("verified"),startAt("0"));
export default new BvDataServices();
import React from 'react'
import Navbar from './Navbar'
import { useEffect } from 'react'
import { useState } from 'react'
import ResaleDataServices from '../services/resale.services'

export default function Resalearea() {
  const [books, setbooks] = useState([])
  useEffect(()=>{
    getBooks();
  },)

   
  const APHandeler= async (id)=>{
    const bookSnap= await ResaleDataServices.getBook(id);
    const newBook={
      img:bookSnap.data().img,
      bidtype:bookSnap.data().bidtype,
      address:bookSnap.data().address,
      contact:bookSnap.data().contact,
      description:bookSnap.data().description,
      land:bookSnap.data().land,
      listedby:bookSnap.data().listedby,
      name:bookSnap.data().name,
      objectID:bookSnap.data().objectID,
      ownerName:bookSnap.data().ownerName,
      parking:bookSnap.data().parking,
      price:bookSnap.data().price,
      propertyType:bookSnap.data().propertyType,
      subRun:bookSnap.data().subRun,
      totalplotArea:bookSnap.data().totalplotArea,
      userID:bookSnap.data().userID,
      whatsAppNumber:bookSnap.data().whatsAppNumber,
     
      visibleInApp:"-1"
    }
    ResaleDataServices.updateBrequest(id,newBook);
    alert("your request has been approved");
  }

  const DEHandeler= async (id)=>{
    // ResaleDataServices.deleteBook(id);
    const bookSnap= await ResaleDataServices.getBook(id);
    const newBook={
      img:bookSnap.data().img,
      bidtype:bookSnap.data().bidtype,
      address:bookSnap.data().address,
      contact:bookSnap.data().contact,
      description:bookSnap.data().description,
      land:bookSnap.data().land,
      listedby:bookSnap.data().listedby,
      name:bookSnap.data().name,
      objectID:bookSnap.data().objectID,
      ownerName:bookSnap.data().ownerName,
      parking:bookSnap.data().parking,
      price:bookSnap.data().price,
      propertyType:bookSnap.data().propertyType,
      subRun:bookSnap.data().subRun,
      totalplotArea:bookSnap.data().totalplotArea,
      userID:bookSnap.data().userID,
      whatsAppNumber:bookSnap.data().whatsAppNumber,
    
      visibleInApp:"-2"
    }
    ResaleDataServices.updateBrequest(id,newBook);
    alert("your request has been rejected");
  }

  const getBooks= async ()=>{
    const data= await ResaleDataServices.getAllBooks();
    console.log(data);
    setbooks(data.docs.map((doc) =>({...doc.data(), id:doc.id})))
  }

  return (
  <>
  <Navbar /> 
 
     <div className="container">
      <div className="row">
        <h2 className='text-center' >resaleandrental</h2>
      {books.map((doc,index)=>{
    return(
      <div className="col-md-4" key={doc.id}>
      <div className="card" style={{width: "18rem"}}>
<img className="card-img-top" style={{height:"250px"}}  src={doc.img} alt=" cap" />
<div className="card-body">
  <h5 className="card-title">Bid type:{doc.bidtype}</h5>
  <p className='card-text' >Address:{doc.address}</p>
  <p className='card-text' >Contact:{doc.contact}</p>
  <p className='card-text'>description: {doc.description}</p>
  <p className='card-text' >land: {doc.land} </p>
  <p className='card-text' >listedBy: {doc.listedby} </p>
  <p className='card-text' >name: {doc.name} </p>

  <p className='card-text'>owenerNAME: {doc.ownerName}</p>
  <p className='card-text' >parking:{doc.parking}</p>
  <p className='card-text' >price:{doc.price}</p>
  <p className='card-text'>propertyType:{doc.propertyType }</p>

  <p className='card-text'>totalplotArea:{doc.totalplotArea}</p>
  <p className='card-text'>userID:{doc.userID}</p>
  <p className='card-text'>whatsAppNumber:{doc.whatsAppNumber}</p>

  <button className="btn btn-primary mx-3"   onClick={(e)=>APHandeler(doc.id)} >Approve</button>
  <button className="btn btn-danger"   onClick={(e)=>DEHandeler(doc.id)} >Reject</button>
</div>
</div>
      </div>
    )
  })}
      </div>
     </div>
  </>
  )
}


import './App.css';
import Home from './components/Home';
import Loginpage from './components/Loginpage';
import { Route,Routes } from 'react-router-dom';
import Brokerveri from './components/Broker_veri';
import Propertyveri from './components/Propertyveri'
import Resalearea from './components/Resalearea'
import Loanpage from './components/Loanpage'
import  Appadver from './components/Appadver'
import Bv from './components/Bv'


function App() {
  return (
   <>
   <Routes>
    <Route exact path='/' element={<Home />}></Route>
    <Route exact path='/login-page' element={<Loginpage />}></Route>
    <Route exact path='/login-page/bv' element={<Brokerveri />}></Route>
    <Route exact path='/login-page/pv' element={<Propertyveri />}></Route>
    <Route exact path='/login-page/rea' element={<Resalearea />}></Route>
    <Route exact path='/login-page/loan' element={<Loanpage />}></Route>
    <Route exact path='/login-page/app-ad' element={<Appadver />}></Route>
    <Route exact path='/login-page/broker' element={<Bv />}></Route>

   </Routes>
    
   
  
   </>
  );
}

export default App;

import React from 'react'
import Navbar from './Navbar'
import { useEffect } from 'react'
import BrokerDataServices from "../services/book.service"
import { useState } from 'react'

// this is actual post builderProject

export default function Broker_veri() {

  const [books, setbooks] = useState([])
  useEffect(()=>{
    getBooks();
  },)

  const DEHandeler= async (id)=>{
    // BrokerDataServices.deleteBook(id);
    const bookSnap= await BrokerDataServices.getBook(id);
    const newBook={
      projectName:bookSnap.data().projectName,
      description:bookSnap.data().description,
      builderName:bookSnap.data().builderName,
      userId:bookSnap.data().userId,
      contactNo:bookSnap.data().contactNo,
      location:bookSnap.data().location,
      listedby:bookSnap.data().listedby,
      projectType:bookSnap.data().projectType,
      objectID:bookSnap.data().objectID,
      subRun:bookSnap.data().subRun,
      visibleInApp:"-2"
      
     }
     BrokerDataServices.updateBrequest(id,newBook);
     alert("your request has been rejected");

  }

  const APHandeler= async (id)=>{
    const bookSnap= await BrokerDataServices.getBook(id);
    const newBook={
     projectName:bookSnap.data().projectName,
     description:bookSnap.data().description,
     builderName:bookSnap.data().builderName,
     userId:bookSnap.data().userId,
     contactNo:bookSnap.data().contactNo,
     location:bookSnap.data().location,
     listedby:bookSnap.data().listedby,
     projectType:bookSnap.data().projectType,
     objectID:bookSnap.data().objectID,
     subRun:bookSnap.data().subRun,
     visibleInApp:"-1"
     
    }
    BrokerDataServices.updateBrequest(id,newBook);
    alert("your request has been accepeted");
  }
  
  const getBooks= async ()=>{
    const data= await BrokerDataServices.getAllBooks();
    console.log(data);
    setbooks(data.docs.map((doc) =>({...doc.data(), id:doc.id})))
  }

  return (

 <>
 <Navbar />

 

 <div className="container">
    <div className="row mt-3">
      <h2 className='text-center'>Post_Builder_Project</h2>
    {books.map((doc,index)=>{
  return(
    <div className="col-md-4" key={doc.id}>
        <div className="card" style={{width:" 18rem"}}>
  <img  className="card-img-top "  style={{height:"200px"}}    src={doc.images} alt="Card  cap" />
  <div className="card-body">
    <h5 className="card-title">projectName:{doc.projectName}</h5>
    <p className="card-text">description:{doc.description}</p>
    <p className='card-text'>builderName{doc.builderName}</p>
    <p className="card-text">userID:{doc.userId}</p>
    <p className="card-text">ContactNo:{doc.contactNo}</p>
    <p className='card-text'>Loaction:{doc.location}</p>
    <p className="card-text">listedBy:{doc.listedby}</p>
   <p className="card-text">projectType:{doc.projectType}</p>
  

    
     <button className="btn btn-success mx-2" onClick={(e)=>APHandeler(doc.id)} >Approve</button>
    <button className="btn btn-danger" onClick={(e)=>DEHandeler(doc.id)}  >Reject</button>
  </div>
</div>
        </div>
  )
 })}
    </div>
 </div>
 </>
  )
}

import React from 'react'
import Navbar from'./Navbar'
import { Link } from 'react-router-dom'

export default function Loginpage() {
  return (
 <>
   <Navbar />

   <section id="login-home">
    <div className="container ">
        <div className="row text-center pages-row ">
          <div className="col-md-3 p">
            <div className="page text-center">
              <Link to='/login-page/bv' className='link-page' >  <h2>Post_Builder_Project </h2></Link>
                
            </div>
          </div>

          <div className="col-md-3 p">
            <div className="page text-center ">
             <Link to='/login-page/pv' className='link-page' > <h2>propertylayouts</h2> </Link>
            </div>
          </div>

          <div className="col-md-3 p">
            <div className="page text-center">
                <Link to='/login-page/app-ad' className='link-page' ><h2>App advertisement</h2></Link>
            </div>
          </div>

          <div className="col-md-3 p">
            <div className="page text-center">
              <Link to='/login-page/loan'   className='link-page'  >  <h2>Loan Request</h2> </Link>
            </div>
          </div>

          <div className="col-md-3 p">
            <div className="page text-center last-page">
                <Link to='/login-page/rea'  className='link-page' ><h2>resaleandrental</h2></Link>
            </div>
          </div>

          
          <div className="col-md-3 p">
            <div className="page text-center last-page">
                <Link to='/login-page/broker'  className='link-page' ><h2>Broker_Verification</h2></Link>
            </div>
          </div>


        </div>
    </div>
 
   </section>
 </>

 
  )
}

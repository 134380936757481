import React from 'react'
import web from '../images/web-3.jpeg'
// import Navbar from './Navbar'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'


export default function Home() {
  const [password, setpassword] = useState("")
  const navigate= useNavigate();

  const subitform=()=>{
    if(password==="Charandolly123"){
      navigate("/login-page");
    }
  }

  return (
   <>
   {/* <Navbar /> */}
   <div className="Home">
   <section id="home ">
  
  <div className="container">
      <div className="row">
       <div className="col-me-10 mx-auto mt-3">
        <div className="row d-flex justify-content-center align-items-center">
        <div className="col-md-6 text-center ">
         <img src={web} alt='dsd' className='img-fluid home-img' />
        </div>

        <div className="col-md-6  mt-3">
         <h1>Welcome Back !</h1>
         <form action=''  onSubmit={subitform} >
         <label for="pass" className="form-label">Password</label>
          <input type='password' required className='form-control' id='pass' value={password}  onChange={(e)=>{setpassword(e.target.value)}} />
          <div className="btn-group d-flex flex-column align-items-center ">
          <button type='btn'   className='btn btn-primary mt-3' >submit</button>
          </div>
       
         </form>
        </div>
        </div>
       </div>
      </div>
  </div>
 
 </section>

   </div>
  
   </>
  )
}

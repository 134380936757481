import React from 'react'
import Navbar from './Navbar'
import LoanDataServices from '../services/loan.services'
import { useEffect } from 'react'
import { useState } from 'react'

export default function Loanpage() {

  const [books, setbooks] = useState([])
  useEffect(()=>{
    getBooks();
  },[])


  const getBooks= async ()=>{
    const data= await LoanDataServices.getAllBooks();
    console.log(data);
    setbooks(data.docs.map((doc) =>({...doc.data(), id:doc.id})))
  }









  return (
   <>
 

   <Navbar />
   <div className="container">
    <div className="row">
        <div className="col-12">
        <table class="table">
  <thead>
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Phone Number</th>
      <th scope="col">Employment Type</th>
      <th scope="col">Notes</th>
    </tr>
  </thead>
  <tbody>
  {books.map((doc,index)=>{
    return(
      <tr key={doc.id}>
      <th >{doc.name}</th>
      <td>{doc.phoneNo}</td>
      <td>{doc.employmentType}</td>
      <td>{doc.notes}</td>
    
    </tr>
    )
  })}
   
  </tbody>
</table>
        </div>
    </div>
   </div>
   </>
  )
}

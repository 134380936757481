// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyBvmNuUyRhgyRfOXo6c_IxvVqF9R45Rmiw",
  authDomain: "uk9-squares-1e66e.firebaseapp.com",
  projectId: "uk9-squares-1e66e",
  storageBucket: "uk9-squares-1e66e.appspot.com",
  messagingSenderId: "668038734561",
  appId: "1:668038734561:web:aabb191040a07ad7f5cda0",
  measurementId: "G-DWNE5HVK2E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export const db= getFirestore(app);
export const storage= getStorage(app);